'use client';

import React from 'react';
import Image from 'next/image';

import {
  BlocksRenderer,
  type BlocksContent,
} from '@strapi/blocks-react-renderer';
import Link from 'next/link';
import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';

export default function BlockRendererClient({
  content,
  className,
  lowerHeadingLevel,

  quoteClassName,
  linkClassName,
  imageClassName,
  paragraphClassName,
  listClassName,
  listItemClassName,
  codeClassName,
  headingClassName,
}: {
  readonly content: BlocksContent;
  className?: string;
  lowerHeadingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  
  quoteClassName?: string;
  linkClassName?: string;
  imageClassName?: string;
  paragraphClassName?: string;
  listClassName?: string;
  listItemClassName?: string;
  codeClassName?: string;
  headingClassName?: string;
}) {
  if (!content) return null;
  return (
    <div className={cn('mgsm-block-content blocks', className)}>
      <BlocksRenderer
        content={content}
        blocks={{
          quote: ({ children }) => {
            return (
              <blockquote className={cn(
                'my-2 text-sm italic text-gray-600 dark:text-gray-400',
                'border-l-4 border-primary/50 pl-2',
                quoteClassName,
              )}>
                {children}
              </blockquote>
            );
          },
          link: ({ children, url }) => {
            const isExternal = url.startsWith('http');
            const LinkComponent = isExternal ? 'a' : Link;
            const linkProps = isExternal ? {
              href: url,
              target: '_blank',
              rel: 'noopener noreferrer',
            } : { href: url };
            return (
              <LinkComponent {...linkProps} className={cn(
                'text-blue-500 dark:text-blue-300 hover:text-primary transition-colors',
                linkClassName,
              )} onDragStart={(e) => e.preventDefault()}>
                {children}
              </LinkComponent>
            );
          },
          image: ({ image }) => {
            return (<motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}  
            >
              <Image
                src={image.url}
                width={image.width}
                height={image.height}
                alt={image.alternativeText || ''}
                className={cn('rounded-lg my-2', imageClassName)}
                onDragStart={(e) => e.preventDefault()}
              />
            </motion.div>);
          },
          list: ({ children, format }) => {
            const sharedClasses = 'ml-2 my-4';
            if (format === 'unordered') {
              return (<ul className={cn('list-disc list-inside', listClassName, sharedClasses)}>{children}</ul>);
            }
            if (format === 'ordered') {
              return (<ol className={cn('list-decimal list-inside', listClassName, sharedClasses)}>{children}</ol>);
            }
          },
          paragraph: ({ children }) => {
            return (
              <p className={cn('leading-7 p-leader', paragraphClassName)}>
                {children}
              </p>
            );
          },
          'list-item': ({ children }) => {
            return (
              <li className={cn(listItemClassName)}>
                {children}
              </li>
            );
          },
          code: ({ children  }) => {
            return (
              <code className={cn('bg-gray-200 dark:bg-gray-800 p-1 rounded text-orange-500', codeClassName)}>
                {children}
              </code>
            );
          },
          heading: ({ level, children }) => {
            const resolvedLevel = lowerHeadingLevel ? level + lowerHeadingLevel : level;
            return React.createElement(`h${resolvedLevel}`, {
              className: (resolvedLevel === 1
                ? 'scroll-m-20 text-3xl lg:text-4xl font-bold tracking-tight text-black dark:text-white'
                : resolvedLevel === 2
                  ? 'mt-6 scroll-m-20 text-2xl lg:text-3xl font-semibold tracking-tight text-gray-800 dark:text-gray-200 transition-colors first:mt-0'
                  : resolvedLevel === 3
                    ? 'mt-4 scroll-m-20 text-xl lg:text-2xl font-semibold tracking-tight text-gray-800 dark:text-gray-200'
                    : resolvedLevel === 4
                      ? 'mt-2 scroll-m-20 text-lg lg:text-xl font-semibold tracking-tight text-gray-800 dark:text-gray-200'
                      : resolvedLevel === 5
                        ? 'mt-2 scroll-m-20 text-base lg:text-lg font-semibold tracking-tight text-gray-800 dark:text-gray-200'
                        : 'mt-2 scroll-m-20 text-base lg:text-lg font-semibold tracking-tight text-gray-800 dark:text-gray-200')
              + ` ${headingClassName ?? ''}`,
            }, children);
          },
        }}
      />
    </div>
  );
}
