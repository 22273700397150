import React from 'react';
import { ArrowBigLeftDashIcon, ArrowBigRightDashIcon } from 'lucide-react';
import LiteYouTubeEmbed, { LiteYouTubeProps } from 'react-lite-youtube-embed';

import { Button } from './ui/button';
import { cn } from '@/lib/utils';

const VideoPlayer = ({ videos, className }: {
  videos: string[];
  className?: string;
}) => {
  const [activeVideo, setActiveVideo] = React.useState<string | null>(videos[0] || null);

  if (!videos || videos.length === 0) {
    return null;
  }

  const buttonClassName = 'mgsm-video-player-button bg-transparent hover:bg-transparent hover:scale-[110%] h-full p-0';
  const videoWrapperClassName = cn(
    'mgsm-video-player-wrapper',
    'w-full h-full',
    'rounded sm:rounded-lg overflow-hidden',
    'shadow-[0px_0px_10px_hsl(var(--primary))]',
  );

  const NextButton = ({ className, iconClassName }: { className?: string; iconClassName?: string }) => {
    const currentIndex = videos.indexOf(activeVideo || '');
    const newIndex = currentIndex === videos.length - 1 ? 0 : currentIndex + 1;
    return (<Button
      variant={'ghost'}
      className={cn(
        buttonClassName,
        'mgsm-video-player-button-next',
        className,
      )}
      onClick={() => setActiveVideo(videos[newIndex])}
    >
      <ArrowBigRightDashIcon className={cn('size-10 shrink-0', iconClassName)} />
    </Button>);
  };

  const PrevButton = ({ className, iconClassName }: { className?: string; iconClassName?: string }) => {
    const currentIndex = videos.indexOf(activeVideo || '');
    const newIndex = currentIndex === 0 ? videos.length - 1 : currentIndex - 1;
    return (<Button
      variant={'ghost'}
      className={cn(
        buttonClassName,
        'mgsm-video-player-button-prev',
        className,
      )}
      onClick={() => setActiveVideo(videos[newIndex])}
    >
      <ArrowBigLeftDashIcon className={cn('size-10 shrink-0', iconClassName)} />
    </Button>);
  };

  const sharedProps: Partial<LiteYouTubeProps & React.RefAttributes<HTMLIFrameElement>> = {
    noCookie: true,
    aspectHeight: 9,
    aspectWidth: 16,
    poster: 'maxresdefault',
  };

  let content: React.ReactNode = null;
  if (videos.length === 1) {
    content = (<div className={cn(
      'mgsm-video-player-single w-full h-full',
      'max-w-none sm:max-w-[750px] mx-auto',
      videoWrapperClassName,
      className,
    )}>
      <LiteYouTubeEmbed
        id={activeVideo || ''}
        title="Video #1"
        {...sharedProps}
      />
    </div>);
  }
  else if (activeVideo) {
    content = (<div className={cn(
      'mgsm-video-player-multi',
      'w-full h-full flex flex-col gap-5',
      className,
    )}>
      <div className='flex flex-row items-center gap-3'>
        <PrevButton className='hidden sm:block' iconClassName='size-16' />
        <div className={cn(
          videoWrapperClassName,
          'mgsm-video-player-multi-video',
        )}>
          <LiteYouTubeEmbed
            id={activeVideo}
            title={`Video #${videos.indexOf(activeVideo) + 1}`}
            {...sharedProps}
          />
        </div>
        <NextButton className='hidden sm:block' iconClassName='size-16' />
      </div>

      <div className='flex flex-row items-center justify-center gap-2 sm:hidden'>
        <PrevButton />
        <NextButton />
      </div>
    </div>);
  }

  return content;
};

export default VideoPlayer;
