'use client';

import React from 'react';
import { APIGlobalServerStatusResponse } from '@repo/api-types';
import { cmsImageUrl, cn } from '@/lib/utils';
import Image from 'next/image';
import LinkButton from '../buttons/link-button';

const ServerCard = ({ server, status }: {
  server: APIGlobalServerStatusResponse['']['server'];
  status: APIGlobalServerStatusResponse['']['status'];
}) => {
  return <div className={cn(
    // 'debug',
    'mgsm-server-card',
    'group relative overflow-hidden w-full max-w-[400px]',
    'bg-background dark:bg-gray-900 shadow-lg rounded-lg',
  )}>
    {server.banner?.data ? <div className='relative overflow-hidden'>
      <Image
        src={cmsImageUrl(server.banner.data.attributes.formats?.medium?.url ?? server.banner.data.attributes.url)}
        alt={server.name}
        width={server.banner.data.attributes.width}
        height={server.banner.data.attributes.height}
        className='w-full h-48 object-cover object-center group-hover:scale-105 transition-transform duration-300'
      />

      {server.recently_wiped && <div className='absolute top-0 left-0 text-white text-xs font-bold px-2 py-1 bg-secondary/85 rounded-br'>
        Recently Wiped
      </div>}
      {status && <div className='absolute top-0 right-0 text-white text-xs font-bold px-2 py-1 bg-primary/85 rounded-bl'>
        {status.map_name ?? 'Unknown Map'}
      </div>}

      <div className='absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-background' />

      <div className='absolute bottom-0 left-0 right-0'>
        <h3 className='text-2xl font-bold px-2 py-1 truncate mb-2'>
          {server.name}
        </h3>
      </div>
    </div> : <div className='bg-primary text-white text-center text-2xl font-bold px-2 py-1'>
      {server.name}
    </div>}

    <div className=''>
      <div className={cn('relative h-6 w-full bg-gray-400 dark:bg-gray-800')}>
        <div className={cn('absolute top-0 left-0 h-full bg-secondary -z-1')} style={{
          width: `${(status?.players ?? 0) / (status?.max_players ?? 1) * 100}%`,
        }} />

        <div className={cn(
          'absolute top-0 text-xs text-muted font-bold left-1/2 transform -translate-x-1/2 text-white -translate-y-[-20%]',
        )}>
          {status ? <>
            {status.players}/{status.max_players} Players
          </> : <>
            Server Currently Unavailable
          </>}
        </div>        
      </div>

      <div className='flex flex-row'>
        <LinkButton
          href={'steam://launch/221100'}
          className='w-full font-semibold rounded-t-none text-white'
          disabled={!status}
        >
          Start Game
        </LinkButton>
      </div>
    </div>
  </div>;
};

export default ServerCard;
