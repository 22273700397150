'use client';
import { ReactNode, useEffect } from 'react';
import { motion, stagger, useAnimate } from 'framer-motion';
import { cn } from '@/lib/utils';

import TypeWriter, {Options} from 'typewriter-effect';
import { ClassValue } from 'clsx';

export const TextGenerateEffect = ({
  words,
  className,
  delay = 0,
  repeatingWordsEnd = [],
  repeatingWordsClassName,
  repeatingWordsDelay = 0,
  repeatingWordsCycle = 2500,
  repeatingWordsOptions = {},
}: {
  words: ({
    text: ReactNode;
    className?: ClassValue;
  })[];
  className?: ClassValue;
  /** Delay in seconds */
  delay?: number;
  repeatingWordsEnd?: string[];
  repeatingWordsClassName?: ClassValue;
  repeatingWordsDelay?: number;
  repeatingWordsCycle?: number;
  repeatingWordsOptions?: Omit<Options, 'words'>;
}) => {
  const usesRepeatingWords = repeatingWordsEnd.length > 0;
  const [scope, animate] = useAnimate();


  useEffect(() => {
    animate(
      'span',
      {
        opacity: 1,
      },
      {
        duration: 2,
        delay: stagger(0.2, {
          startDelay: delay,
        }),
      }
    );
  }, [animate, delay]);

  const renderWords = () => {
    return (
      <motion.span ref={scope}>
        {words.map((word, idx) => {
          return (
            <motion.span
              key={'tge#span#' + idx}
              className={cn('opacity-0', word.className)}
            >
              {word.text}{' '}
            </motion.span>
          );
        })}
        {usesRepeatingWords && <span className={cn('text-primary', repeatingWordsClassName)}><TypeWriter
          component={'span'}
          onInit={(typewriter) => {
            setTimeout(() => {
              typewriter
                .typeString(repeatingWordsEnd[0])
                .pauseFor(repeatingWordsCycle)
                .deleteAll()
                .typeString(repeatingWordsEnd[1])
                .pauseFor(repeatingWordsCycle)
                .deleteAll()
                .typeString(repeatingWordsEnd[2])
                .pauseFor(repeatingWordsCycle)
                .deleteAll()
                .typeString(repeatingWordsEnd[3])
                .pauseFor(repeatingWordsCycle)
                .deleteAll()
                .typeString(repeatingWordsEnd[4])
                .pauseFor(repeatingWordsCycle)
                .deleteAll()
                .start();
            }, repeatingWordsDelay);
          }}
          options={{
            strings: repeatingWordsEnd,
            autoStart: false,
            loop: true,
            delay: 100,
            deleteSpeed: 100,
            ...repeatingWordsOptions,
            // Note: Makes cursor always visible =((
            // cursorClassName: cn(cursorClassName),
          }}
        /></span>}
      </motion.span>
    );
  };

  return (
    <span className={cn(className)}>
      {renderWords()}
    </span>
  );
};
