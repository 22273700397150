'use client';

import React from 'react';
import { IconType, ZapIcon } from '../icons';
import { Button, ButtonProps, buttonVariants } from '../ui/button';
import { cn } from '@/lib/utils';
import Link from 'next/link';

// References:
// https://freefrontend.com/tailwind-buttons/

export type BrandButtonProps = {
  className?: string;
  children?: React.ReactNode;
  Icon?: IconType | null;
  href?: string | null;
  variant?: ButtonProps['variant'];
  wrapperClassName?: string;
  iconClassName?: string;
  disableAnimation?: boolean;
} & ButtonProps;

const BrandButton = ({
  className,
  children,
  variant = null,
  href = null,
  size = 'lg',
  Icon = ZapIcon,
  disabled = false,
  wrapperClassName,
  iconClassName,
  disableAnimation = false,
  ...props
}: BrandButtonProps) => {
  const InnerContent = () => {
    return (<>
      <span className="absolute bottom-0 right-0 w-8 h-20 -mb-8 -mr-5 transition-all duration-300 ease-out transform rotate-45 translate-x-5 bg-white opacity-10 group-hover:translate-x-0" />
      <span className="absolute top-0 left-0 w-20 h-8 -mt-1 -ml-12 transition-all duration-300 ease-out transform -rotate-45 -translate-x-5 bg-white opacity-10 group-hover:translate-x-0" />

      <span className="relative z-[2] flex items-center">
        {Icon && <Icon className={cn(
          'w-5 h-5 mr-2',
          !disableAnimation && 'group-hover:animate-spin',
          iconClassName
        )} />}
        {children}
      </span>
    </>);
  };

  const styling = (add?: string) => cn(
    add,
    'box-border relative z-[3] inline-flex items-center justify-center w-auto px-6 py-3',
    'text-white bg-primary rounded-md',
    'overflow-hidden',
    'shadow-md font-bold outline-offset-4',
    // 'group-hover/wrapper:scale-90 group-focus/wrapper:scale-90',
    // 'group-hover/wrapper:px-4 group-hover/wrapper:mx-2 focus:px-4 focus:mx-2',
    'ring-offset-2 ring-1 ring-indigo-300 ring-offset-indigo-200 hover:ring-offset-white ease',
    'group transition-all duration-300 dark:outline-white',
    className,
  );

  const Wrapper = ({ children }: { children: React.ReactNode }) => {
    return (<div className={cn(
      'mgsm-brand-button',
      'group/wrapper',
      disabled && 'opacity-50 cursor-not-allowed',
      wrapperClassName
    )}>{children}</div>);
  };

  if (href) {
    const buttonStyles = styling(buttonVariants({
      size,
      variant,
    }));

    const isExternal = href.startsWith('http');
    return (<Wrapper>
      <Link href={href}
        className={cn(buttonStyles, disabled && 'pointer-events-none')}
        onDragStart={(e) => e.preventDefault()}
        target={isExternal ? '_blank' : undefined}
        rel={isExternal ? 'noopener noreferrer' : undefined}
      >
        <InnerContent />
      </Link>
    </Wrapper>);
  }

  return (<Wrapper>
    <Button
      variant={variant}
      size={size}
      className={cn(styling(), disabled && 'pointer-events-none')}
      onDragStart={(e) => e.preventDefault()}
      {...props}
    >
      <InnerContent />
    </Button>
  </Wrapper>);
};

export default BrandButton;
