'use client';

import React from 'react';
import { LinesMain } from '../svg-elements';
import { cmsImageUrl, cn, transformBlockContent } from '@/lib/utils';
import { SiteConfiguration } from '@repo/api-types/generated';
import BlockRendererClient from '../block-renderer-client';
import Image from 'next/image';

const ContactBox = ({ siteConfiguration }: {
  siteConfiguration: SiteConfiguration;
}) => {
  return (<div className={cn(
    'mgsm-contact-box group',
    siteConfiguration.attributes.contact.contactBoxImage
      ? 'max-w-5xl'
      : 'max-w-2xl',
    'sm:p-8 p-4 pt-3 sm:pt-7 relative z-10 rounded-sm overflow-hidden shadow-inner sm:shadow-lg',
    'bg-gray-50 dark:bg-gray-900'
  )}>
    <LinesMain className={cn(
      'mgsm-lines-main',
      'absolute bottom-0 left-1/3 w-full h-auto z-[-1]',
      'pointer-events-none duration-300 dark:lg:opacity-100 dark:opacity-80',
      'dark:md:opacity-60 dark:sm:opacity-40 dark:opacity-20',
      'md:opacity-80 sm:opacity-60 opacity-40',
      'text-secondary'
    )} />

    <div className='mgsm-contact-box-content flex flex-col lg:flex-row gap-4'>
      <div>
        <h1 className="text-4xl font-bold">
          {siteConfiguration.attributes.contact.contactBoxTitle}
        </h1>
        <BlockRendererClient
          className='mt-4'
          content={transformBlockContent(siteConfiguration.attributes.contact.contactBoxContent, siteConfiguration)}
        />
      </div>
      {siteConfiguration.attributes.contact.contactBoxImage && (<div
        className={cn(
          'w-full overflow-hidden',
          'rounded shadow-lg dark:shadow-none',
          'border-4 border-secondary/50 group-hover:border-secondary/75',
          'transition-all duration-300',
          'bg-background',
        )}
      >
        <Image
          priority
          className={cn(
            'h-full w-full object-cover',
            'transition-all duration-300 transform group-hover:scale-110',
            'dark:filter dark:brightness-75',
            'dark:group-hover:filter dark:group-hover:brightness-100',
          )}
          src={cmsImageUrl(
            siteConfiguration.attributes.contact.contactBoxImage.data.attributes.formats?.large?.url
            ?? siteConfiguration.attributes.contact.contactBoxImage.data.attributes.url
          )}
          alt={siteConfiguration.attributes.contact.contactBoxImage.data.attributes.alternativeText}
          width={siteConfiguration.attributes.contact.contactBoxImage.data.attributes.formats?.large?.width
            ?? siteConfiguration.attributes.contact.contactBoxImage.data.attributes.width}
          height={siteConfiguration.attributes.contact.contactBoxImage.data.attributes.formats?.large?.height
            ?? siteConfiguration.attributes.contact.contactBoxImage.data.attributes.height}
          onDragStart={(e) => e.preventDefault()}
        />
      </div>)}
    </div>
  </div>);
};

export default ContactBox;
