'use client';

import { cn } from '@/lib/utils';
import { ClassValue } from 'clsx';
import { useEffect, useState } from 'react';
import { loadSlim } from '@tsparticles/slim';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import type { ISourceOptions, Container } from '@tsparticles/engine';
import { ParticlesConfiguration } from '@repo/api-types/generated';

export const defaultTSParticlesOptions = (particlesConfiguration: ParticlesConfiguration): ISourceOptions => ({
  fullScreen: {
    enable: false,
  },
  background: {
    color: {
      value: 'transparent',
    },
  },
  fpsLimit: 120,
  interactivity: {
    events: {
      onClick: {
        enable: false,
        mode: 'push',
      },
      onHover: {
        enable: false,
        mode: 'repulse',
      },
      resize: true as never,
    },
    modes: {
      push: {
        quantity: 4,
      },
      repulse: {
        distance: 200,
        duration: 0.4,
      },
    },
  },
  particles: {
    color: {
      value: particlesConfiguration.attributes.particleColor,
    },
    links: {
      color: particlesConfiguration.attributes.linksColor,
      distance: 150,
      enable: true,
      opacity: 0.5,
      width: 1,
    },
    move: {
      direction: 'none',
      enable: true,
      outModes: {
        default: 'bounce',
      },
      random: false,
      speed: 1.5,
      straight: false,
    },
    number: {
      density: {
        enable: true,
        // area: 800,
      },
      value: particlesConfiguration.attributes.numberOfParticles,
    },
    opacity: {
      value: 0.5,
    },
    shape: {
      type: 'circle',
    },
    size: {
      value: { min: 1, max: 5 },
    },
  },
  detectRetina: true,
});

export const mergeCustomConfiguration = (particlesConfiguration: ParticlesConfiguration, options: ISourceOptions): ISourceOptions => {
  if (!particlesConfiguration.attributes.customConfiguration) {
    return options;
  }

  return {
    ...options,
    particles: {
      ...options.particles,
      color: {
        value: particlesConfiguration.attributes.customConfiguration.particleColor,
        ...(options.particles?.color ?? {}),
      },
      links: {
        color: particlesConfiguration.attributes.customConfiguration.linksColor,
        ...(options.particles?.links ?? {}),
      },
      number: {
        value: particlesConfiguration.attributes.customConfiguration.numberOfParticles,
        ...(options.particles?.number ?? {}),
      },
    },
  };
};

export type TSParticlesComponentProps = {
  className?: ClassValue;
  options?: ISourceOptions | null;
  particlesConfiguration: ParticlesConfiguration;
};

const TSParticlesComponent = ({
  className,
  particlesConfiguration,
  options = particlesConfiguration.attributes.customConfiguration
    ? mergeCustomConfiguration(particlesConfiguration, particlesConfiguration.attributes.customConfiguration)
    : null,
}: TSParticlesComponentProps) => {
  const [ init, setInit ] = useState(false);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, [init]);

  const particlesLoaded = async (_container: Container | undefined): Promise<void> => {
  };

  return (<>
    {init && <Particles
      id="tsparticles"
      particlesLoaded={particlesLoaded}
      className={cn(
        'absolute inset-0 w-full h-full',
        className
      )}
      options={options ?? defaultTSParticlesOptions(particlesConfiguration)}
    />}
  </>);
};

export default TSParticlesComponent;
