'use client';

import { cn } from '@/lib/utils';
import React from 'react';
import { TextGenerateEffect } from '../ui/interactive/text-generate-effect';
import { ClassValue } from 'clsx';
import { motion } from 'framer-motion';
import BrandButton from '../buttons/brand-button';
import { Media, ParticlesConfiguration } from '@repo/api-types/generated';
import TSParticlesComponent from '../tsparticles/main';
import SectionBackground from '../sections/section-background';
import VideoPlayer from '../video-player';

export type ButtonConfiguration = {
  label: string;
  url: string;
  enabled: boolean;
};

export type PageHeroProps = {
  title: {
    text: string;
    className?: string;
  }[];
  subtitle: string;
  paragraph: string;
  paragraphClassName?: ClassValue;
  paragraphRepeatingWords?: string[];
  className?: ClassValue;
  titleDelay?: number;
  paragraphDelay?: number;
  repeatingWordsDelay?: number;
  stripeDelay?: number;
  actionsDelay?: number;
  titleClassName?: ClassValue;
  subtitleClassName?: ClassValue;
  children?: React.ReactNode;
  background?: Media;
  backgroundOverlayOpacity?: number;
  action: ButtonConfiguration;
  actionTwo?: ButtonConfiguration;
  particlesConfiguration: ParticlesConfiguration | null;
  disableParticles?: boolean;
  videos?: unknown;
};

export const HorizontalLine = ({
  animate = false,
  delay = 0,
}: { animate?: boolean, delay?: number; }) => (<motion.div
  initial={{ width: animate ? 0 : '100%' }}
  animate={{ width: '100%' }}
  transition={{ delay: animate ? delay : 0, duration: animate ? 1.5 : 0 }}
  className='absolute inset-0 z-[-1]'
>
  <div className={'absolute inset-x-10 bottom-[-.05rem] z-[-1] bg-gradient-to-r from-transparent via-primary/25 to-transparent h-[2px] w-3/4 blur-sm'} />
  <div className={'absolute inset-x-10 bottom-[-.05rem] z-[-1] bg-gradient-to-r from-transparent via-primary/25 to-transparent h-px w-3/4'} />
  <div className="absolute inset-x-40 bottom-[-.05rem] z-[-1] bg-gradient-to-r from-transparent via-primary to-transparent h-[5px] w-1/4 blur-sm opacity-0 dark:opacity-100" />
  <div className="absolute inset-x-40 bottom-[-.05rem] z-[-1] bg-gradient-to-r from-transparent via-primary to-transparent h-px w-1/4" />
</motion.div>);

const PageHero = ({
  title,
  subtitle,
  paragraph,
  paragraphClassName,
  className,
  titleDelay = 1,
  paragraphDelay = 2,
  stripeDelay = titleDelay + .75,
  actionsDelay = stripeDelay + .75,
  titleClassName,
  subtitleClassName,
  children,
  background,
  backgroundOverlayOpacity = 50,
  action,
  actionTwo,
  particlesConfiguration,
  disableParticles = false,
  videos = [],
}: PageHeroProps) => {
  const usesVideos = Array.isArray(videos) && videos.length > 0 && videos.every((video) => typeof video === 'string');

  return (<div className={cn('h-full w-full bg-gray-50 dark:bg-gray-900 border border-b')}>
    <section id="page-hero-section" className={cn(
      'mgsm-page-hero',
      'flex flex-col justify-center items-center text-center grow',
      'py-10 px-2 relative z-10',
      'flex items-center justify-center',
      'mt-[-1px]',
      'dark:bg-grid-transparent bg-grid-transparent',
      'min-h-[85vh] overflow-hidden',
      className
    )}>
      <HorizontalLine />

      {particlesConfiguration?.attributes.enabled && !disableParticles && (
        <TSParticlesComponent
          particlesConfiguration={particlesConfiguration}
          className="z-10"
        />
      )}

      {background && (<SectionBackground
        background={background}
        backgroundOverlayOpacity={backgroundOverlayOpacity}
      />)}

      <motion.div className='flex flex-col items-center w-full max-w-4xl'>
        <h1 className={cn(
          'relative z-10 text-lg text-primary uppercase font-extrabold',
          subtitleClassName
        )}>
          <TextGenerateEffect words={subtitle.split(' ').map((e) => ({ text: e }))} />
        </h1>
      
        <div className='relative z-10 my-3 flex items-center justify-center px-1'>
          <h2 className={cn(
            'text-3xl font-extrabold leading-tight tracking-tight text-gray-900 sm:text-4xl dark:text-white',
            'max-w-[650px]',
            // backgroundURL && 'text-white',
            titleClassName
          )}>
            <TextGenerateEffect words={title} delay={titleDelay} />
          </h2>
          <HorizontalLine animate delay={stripeDelay} />
        </div>

        {usesVideos ? <motion.div
          className='relative z-10 mt-3 w-full h-full'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: paragraphDelay, duration: 1 }}
        >
          <VideoPlayer videos={videos as string[]} />
        </motion.div> : <motion.p
          className={cn(
            'relative z-10 text-base font-normal text-gray-700 dark:text-gray-300 sm:text-xl max-w-2xl',
            // backgroundURL && 'text-gray-300',
            paragraphClassName
          )}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: paragraphDelay, duration: 1 }}
        >
          {paragraph}
          {/* <TextGenerateEffect
            words={paragraph.split(' ').map((e) => ({ text: e }))}
            delay={paragraphDelay}
            repeatingWordsEnd={paragraphRepeatingWords}
            repeatingWordsClassName='text-primary font-bold italic'
            repeatingWordsDelay={repeatingWordsDelay}
          /> */}
        </motion.p>}
        
        <motion.div className='relative z-10 flex flex-row gap-3 mt-3'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: actionsDelay, duration: 1 }}
        >
          {children ? <>{children}</> : <>
            <BrandButton className='mt-3' href={action.url}>
              {action.label}
            </BrandButton>
            {actionTwo && actionTwo.enabled && <>
              <BrandButton Icon={null} className={cn(
                'bg-primary-foreground text-gray-500 dark:text-gray-400 hover:dark:text-white hover:text-black',
                'mt-3'
              )} variant={'secondary'} href={actionTwo.url}>
                {actionTwo.label}
              </BrandButton>
            </>}
          </>}
        </motion.div>
      </motion.div>


      <div className="absolute pointer-events-none inset-0 bg-primary/10 [mask-image:radial-gradient(ellipse_at_center,transparent_0%,white)] dark:[mask-image:radial-gradient(ellipse_at_center,transparent_0%,black)]">
      </div>
    </section>
  </div>);
};

export default PageHero;
